import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import axios from 'axios';
import SEO from '../components/seo'; 

const CheckoutOrders = ({ location }) => {
  const [order, setOrder] = useState(location.state?.order || null);
  const [status, setStatus] = useState(order?.status || 'Pending');

  useEffect(() => {
    if (!order) {
      const storedOrder = localStorage.getItem('latestOrder');
      if (storedOrder) {
        setOrder(JSON.parse(storedOrder));
      } else {
        alert('Nothing was found. Once you checkout an order comeback!');
       // navigate('/catalogue');
        navigate('/');
      }
    }
  }, [order]);

  const handleStatusChange = async (newStatus) => {
    try {
      const response = await axios.put(`${process.env.GATSBY_API_URL}/checkout/${order._id}`, { status: newStatus });
      setOrder(response.data);
      setStatus(newStatus);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  if (!order) {
    return null; 
  }

  return (
    <Layout>
      <SEO
        title={`Order Confirmation - ${order._id}`}
        description="View your order details, including status, shipping address, payment method, and items ordered."
        keywords="order confirmation, order details, order status, shipping address, payment method"
      />
      <Container>
        <Heading>Order Confirmation</Heading>
        <Paragraph>Thank you for your order!</Paragraph>
        <Subheading>Order Details</Subheading>
        <Paragraph><strong>Order ID:</strong> {order._id}</Paragraph>
        <Paragraph><strong>Status:</strong> 
          <StatusSelect value={status} onChange={(e) => handleStatusChange(e.target.value)}>
            <option value="Pending">Pending</option>
            <option value="Processing">Processing</option>
            <option value="Shipped">Shipped</option>
            <option value="Delivered">Delivered</option>
            <option value="Cancelled">Cancelled</option>
          </StatusSelect>
        </Paragraph>
        <Subheading>Shipping Address</Subheading>
        <Paragraph>{order.shippingAddress.address}, {order.shippingAddress.city}, {order.shippingAddress.state}, {order.shippingAddress.zip}, {order.shippingAddress.country}</Paragraph>
        <Subheading>Payment Method</Subheading>
        <Paragraph>{order.paymentMethod}</Paragraph>
        <Subheading>Order Items</Subheading>
        <List>
          {order.orderItems.map((item, index) => (
            <ListItem key={index}>{item.productTradeName} (x{item.quantity})</ListItem>
          ))}
        </List>
      </Container>
    </Layout>
  );
};

export default CheckoutOrders;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const Heading = styled.h1`
  color: #03989e; 
  margin-bottom: 20px;
`

const Subheading = styled.h2`
  color: #027a79; 
  margin-top: 20px;
`

const Paragraph = styled.p`
  margin: 10px 0;
`

const StatusSelect = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  margin: 10px 0;
  &:focus {
    border-color: #03989e;
    outline: none;
  }
`

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`

const ListItem = styled.li`
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
`
